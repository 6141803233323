.dizuobiaozhu{
    opacity: 0;
}
.anxiagensui{
    opacity: 0;
}
.waikuang{
    opacity: 0;
}
.neikuang{
    opacity: 0;
}
.fangxingdizuo{
    opacity: 0;
}
.dizuosvg{
    position: absolute;
}
.anniusvg{
    position: absolute;box-sizing: border-box;
    
}
.huakuaiShadow{
    background: rgba(35, 38, 41, 1);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
}
