body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar{
  display: none;
  width: 0;

}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


