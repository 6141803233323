.navLineOne{
    transform:'translateY(0%)';
    clip-path: inset(0% 49.9% 0% 51.1%); 
}
.navLineTwo{
    transform:'translateY(0%)';
    clip-path: inset(0% 49.9% 0% 51.1%); 
}
.menu{
    transform:'translateY(100%)';
}
a {
    text-decoration: none;
}
a.active h4 {
    color:rgb(255, 255, 255);
}