@keyframes shake {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(2deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-2deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.invalid {
    border:1px solid red;
    animation: shake .2s ease-in-out 0s 2;
}